<template>
  <div class="notifications-container">
    <div class="notifications-secondary-list">
      <btn-group
        class="vertical block mb-2"
        style="max-width: 250px"
        v-if="$store.state.session.user.user_is_using_temp_pass"
      >
        <btn class="btn lg danger square" @click="() => $store.dispatch('checkPassword')">
          <template #icon>
            <font-awesome-icon icon="key" />
          </template>
          <span style="font-size: 0.6em">
            You are using a temporary password, click here to change your password!
          </span>
        </btn>
      </btn-group>
    </div>
    <container>
      <card-section>
        <template #title>
          <span><font-awesome-icon icon="bullhorn" class="mr-2" /> Notifications</span>
        </template>
        <list
          class="nopadding notification-list"
          type="notification"
          :permanentFilters="filters"
          :showOptions="false"
        />
      </card-section>

      <card-section>
        <template #title>
          <span><font-awesome-icon icon="history" class="mr-2" /> My recent activity</span>
        </template>
        <list
          class="nopadding notification-list"
          type="activity"
          :permanentFilters="{
            activity_creator: `user-${$store.state.session.user.user_id}`
          }"
          :show-header-search="false"
          :showOptions="false"
        />
      </card-section>
    </container>
  </div>
</template>

<script>
import BtnMixin from '../../components/mixins/Button'

export default {
  name: 'NotificationsList',
  mixins: [BtnMixin],
  data() {
    return {
      endOfSet: false,
      filters: {
        notification_owner: `user-${this.$store.state.session.user.user_id}`
      }
    }
  },
  computed: {
    set() {
      const all = this.$store.state.Notification.all
      return Object.keys(all)
        .map((n) => c.denormalize(all[n]))
        .sort((a, b) => b.notification_id - a.notification_id)
    }
  },
  methods: {
    markSeen() {
      return this.$store.dispatch('Notification/markAllSeen')
    },
    more() {
      this.addLoading()
      return this.$store.dispatch('Notification/more').then(({ endOfSet }) => {
        this.removeLoading()
        this.endOfSet = endOfSet
      })
    }
  },
  mounted() {
    if (!Object.keys(this.$store.state.Notification.all).length) {
      this.$store.dispatch('Notification/get')
    }
    this.markSeen()
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.notifications-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  > * {
    margin: 0 0.5em 1em 0.5em;
  }
  .notifications-main-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    user-select: none;
    .notifications-list-container {
      max-width: 400px;
      min-width: 350px;
      .notifications-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        min-height: 400px;
        font-size: 1.1em;
        font-weight: bold;
        border-radius: 10px;
        border: 1px dashed $cool-gray-300;
      }
      .notifications-list {
        .notifications-item {
          padding: 0;
          &:hover {
            cursor: pointer;
            background: rgba($primary, 0.1);
          }
          .notifications-item-inner {
            padding: 0.75rem 1.25rem;
          }
        }
      }
    }
  }

  .notifications-secondary-list {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    .card {
      margin-bottom: 1em;
      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        .glyphicon,
        .fa {
          margin: 0 0.5em;
        }
      }
    }
  }
}

.notification-list {
  @media (max-width: 576px) {
    margin: 0 !important;
  }
}
</style>
