<template>
  <scroll-container class="py-2">
    <notifications />
  </scroll-container>
</template>

<script>
import Notifications from '../ui/Notifications.vue'

export default {
  name: '',
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: {
    Notifications
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
